import RoomListComponent from "@/components/inventory/room/roomList/RoomList.component";
import { policyList } from "@/enums/permissions";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { ActionSubheader, TableAction, TableItem } from "helix-vue-components";
import { roomService } from "./room.service";

class RoomActionService {
  public getGeneralActions(
    modalCallback: () => void,
    filtersCallback: () => void,
    connectionModalCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        action: () => {
          modalCallback();
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createInventoryRooms
          )
      },
      {
        icon: "far fa-sync-alt",
        action: () => {
          connectionModalCallback();
        },
        visibleCondition: () =>
          RoomListComponent.currentBiotrackStatus &&
          (store.getters["PermissionsModule/hasPermission"](
            policyList.createInventoryRooms
          ) ||
            store.getters["PermissionsModule/hasPermission"](
              policyList.modifyInventoryRooms
            ) ||
            store.getters["PermissionsModule/hasPermission"](
              policyList.enableDisableInventoryRooms
            ))
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent",
          action: roomService.searchEvent()
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  public getRowActions(
    callback: (arg: TableItem) => void,
    loadModal: (error: string) => void
  ): TableAction[] {
    return [
      {
        visibleCondition: item =>
          item.item.room_type !== "SALES_FLOOR" &&
          item.item.room_type !== "REFUND_RESTOCK_ROOM" &&
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyInventoryRooms
          ),
        icon: "fal fa-pen",
        action: (...arg: TableItem[]) => {
          callback(arg[0]);
        }
      },
      {
        icon: "",
        switch: "is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableInventoryRooms
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: String(i18n.t("rooms.room_toggle_confirm")),
          modalSuccessText: "yes",
          modalSuccessAction: ({ item, unselectModal, modelValue }) => {
            roomService
              .toggleRoom(item, modelValue)
              .then(() => unselectModal())
              .catch(error => {
                loadModal(error.response.data.data);
              })
              .finally(() => store.dispatch("RoomModule/loadRooms"));
          },
          modalCancelText: "No"
        }
      }
    ];
  }
}

export const roomActionService = new RoomActionService();
