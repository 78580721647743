import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import { Room, roomModel } from "@/interfaces/room";
import { Traceability } from "@/interfaces/traceability";
import { i18n } from "@/plugins/i18n";
import { Callback, CallbackPromise } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./RoomForm.template.vue";

const namespace: string = "RoomModule";
export interface RoomType {
  value: string;
  roomType: string;
}

@Component({
  mixins: [Template]
})
export default class RoomFormComponent extends Vue {
  @Action("addRoom", { namespace })
  public saveRoomAction!: CallbackPromise<Room>;

  public title = "add_room";

  public edit = false;

  public isSaving = false;

  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  protected model: Room = {
    ...roomModel,
    data: ""
  };

  @Prop({ default: null })
  protected item!: Room;

  protected roomTypes: RoomType[] = [
    { value: "NORMAL_ROOM", roomType: i18n.t("rooms.normal_room").toString() },
    {
      value: "BACKSTOCK_ROOM",
      roomType: i18n.t("rooms.back_stock").toString()
    }
  ];

  public async mounted() {
    if (this.item) {
      this.model = { ...this.item };
      this.title = "edit_room";
      this.edit = true;
    }
  }

  public async renderTraceabilityModal(
    statusName: string,
    descriptionName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string
  ) {
    this.$modals.load(
      TraceabilityConfirm,
      { size: "fit", positionX: "center", positionY: "center" },
      {
        modalData: {
          status: {
            name: this.$t(statusName),
            style: "fontSize:27px ; fontWeight:600;"
          },
          description: {
            name: this.$t(descriptionName),
            style: "fontSize:23px ;"
          },
          title: {
            name: this.$t("biotrack_traceability.duplicate_title"),
            style: "fontSize:30px ; letter-spacing: 0px;"
          },
          titleAvatar: {
            name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
            size: 80
          },
          loading: this.$t(loader),
          acceptButton: accept,
          cancelButton: cancel,
          acceptButtonValue: acceptValue
        }
      }
    );
  }

  public async save() {
    this.isSaving = true;
    if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
      const statusName = this.edit
        ? "biotrack_traceability.modifying_room"
        : "biotrack_traceability.room_creation";
      const descriptionName = this.edit
        ? "biotrack_traceability.modifying_room_description"
        : "biotrack_traceability.room_creation_description";

      this.renderTraceabilityModal(
        statusName,
        descriptionName,
        "audit.loading",
        false,
        false,
        ""
      );
      const rooms = await this.saveRoomAction({
        room: this.model,
        edit: this.edit
      });
      EventBus.$emit("removePopup", true);
      if (
        rooms.status === "error" &&
        rooms.data === `you can't add another room for name ${this.model.name}`
      ) {
        await this.renderTraceabilityModal(
          "biotrack_traceability.duplicate_message",
          "biotrack_traceability.duplicate_description",
          "",
          true,
          false,
          "OK"
        );
      } else if (
        rooms.status === "error" &&
        rooms.data ===
          "Traceability api error, You provided an invalid location license number."
      ) {
        await this.renderTraceabilityModal(
          "biotrack_traceability.invalid_license_number",
          "biotrack_traceability.invalid_license_description",
          "",
          true,
          false,
          "OK"
        );
      }
      this.$emit("closeModal");
      this.$emit("resolve");
    } else {
      await this.saveRoomAction({
        room: this.model,
        edit: this.edit
      });
      this.$emit("closeModal");
      this.$emit("resolve");
    }
  }
  public closeModal() {
    this.$emit("closeModal");
    this.$emit("reject");
  }
}
