export interface Room {
  data: string;
  id: number | null;
  is_active: boolean;
  location_id: number | null;
  name: string;
  room_type: string;
  quantity?: number;
  created_at?: string;
  error?: { message: string };
  status?: string;
}

export const roomModel = {
  id: null,
  is_active: true,
  location_id: null,
  name: "",
  room_type: "NORMAL_ROOM"
};
