import { FieldType, Form } from "@/interfaces/fields";
import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const roomForm: Form = {
  columns: 1,
  fields: [
    {
      title: "",
      fields: [
        {
          type: FieldType.string,
          label: "name",
          modelField: "name",
          validationRules: "required"
        },
        {
          type: FieldType.dropdown,
          label: "type",
          modelField: "type",
          validationRules: "required",
          options: {
            align: "in-line",
            itemText: "text",
            itemValue: "value",
            items: [
              { value: "NORMAL_ROOM", text: i18n.t("rooms.normal_room") },
              { value: "SALES_FLOOR", text: i18n.t("rooms.sales_floor") },
              { value: "BACKSTOCK_ROOM", text: i18n.t("rooms.back_stock") },
              {
                value: "REFUND_RESTOCK_ROOM",
                text: i18n.t("rooms.refund_restock_room")
              }
            ]
          }
        }
      ]
    }
  ]
};

export const RoomsTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("name").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "room_type",
    label: i18n.t("room_type").toString(),
    fieldComponent: TableFieldType.enumText,
    enum: {
      REFUND_RESTOCK_ROOM: i18n.t("inventory.refund_restock_room").toString(),
      NORMAL_ROOM: i18n.t("inventory.normal_room").toString(),
      BACKSTOCK_ROOM: i18n.t("inventory.backstock_room").toString(),
      SALES_FLOOR: i18n.t("inventory.sales_floor").toString()
    },
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];
