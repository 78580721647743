import { ResetService } from "@/decorators/resetService.decorator";
import { HttpQuery } from "@/interfaces/httpQuery";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { Room } from "@/interfaces/room";
import { Traceability } from "@/interfaces/traceability";
import { EventBus, pusherEvents } from "@/internal";
import { RoomsTableMetadata } from "@/metadata/room";
import { i18n } from "@/plugins/i18n";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { roomActionService } from "@/services/room.action.service";
import { roomService } from "@/services/room.service";
import { traceabilityService } from "@/services/traceability.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import TraceabilityConfirm from "../../../sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import RoomFormComponent from "../roomForm/RoomForm.component";
import Template from "./RoomList.template.vue";

const namespace: string = "RoomModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(roomService)
export default class RoomListComponent extends Vue {
  public static currentBiotrackStatus: boolean = false;
  @Getter("rooms", { namespace })
  public rooms!: Room[];
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Action("loadRooms", { namespace })
  public getRoomsAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public paginate = roomService.paginationAction();
  public bioTrackTraceability!: Traceability[];
  public headers: TableHeader[] = RoomsTableMetadata;
  public showFilters = false;
  public activeFilter = false;
  public generalActions: ActionSubheader[] = roomActionService.getGeneralActions(
    this.openModal,
    this.filtersCallback,
    this.connectionModalCallback
  );
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  public rowActions: TableAction[] = roomActionService.getRowActions(
    this.openModal,
    this.disableModel
  );
  public modalData = { title: "", width: "500px", height: "470px" };

  protected query: HttpQuery = {
    ...INITIAL_QUERY_STATE
  };
  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }
  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */
  public onRoomListFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerSearch(param);
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      itemsPerPage: pagination.itemsPerPage,
      currentPage: pagination.currentPage
    });
  }

  public openModal(arg?: any) {
    this.$modals.load(
      RoomFormComponent,
      {
        size: "fit",
        positionY: "top"
      },
      {
        item: arg
      }
    );
  }

  public async renderTraceabilityPopupModal(
    titleName: string,
    statusName: string,
    descriptionName: string,
    messageName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            status: {
              name: this.$t(statusName),
              style: "fontSize:27px ; fontWeight:600"
            },
            title: {
              name: String(this.$t(titleName)).toUpperCase(),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: this.$t(messageName),
              style: "fontSize:30px ; fontWeight:600"
            },
            description: {
              name: this.$t(descriptionName),
              style: "fontSize:23px"
            },
            loading: this.$t(loader),
            acceptButton: accept,
            cancelButton: cancel,
            acceptButtonValue: acceptValue
          }
        }
      )
      .catch(() => false)) as boolean;
    if (!confirm) {
      return;
    }
  }

  // There are some scenarios for which multiple pop-ups needs to be displayed at a time.
  public async connectionModalCallback() {
    if (await this.$validator.validateAll()) {
      this.renderTraceabilityPopupModal(
        "biotrack_traceability.inventory_rooms_title",
        "",
        "biotrack_traceability.syncing_inventory_rooms_with_biotrack_traceability",
        "",
        "audit.loading",
        false,
        false,
        ""
      );
    }

    const syncingStatus = await traceabilityService.syncCheckAPI();
    EventBus.$emit("removePopup", true);
    if (this.bioTrackTraceEnabled && syncingStatus.status === "success") {
      this.renderTraceabilityPopupModal(
        "biotrack_traceability.inventory_rooms_title",
        "",
        "biotrack_traceability.syncing_inventory_rooms_with_biotrack_traceability_successful",
        "biotrack_traceability.syncing_successful",
        "",
        true,
        false,
        "ok"
      );
    } else if (
      this.bioTrackTraceEnabled ||
      syncingStatus.status === "error" ||
      !syncingStatus.data
    ) {
      this.renderTraceabilityPopupModal(
        "biotrack_traceability.inventory_rooms_title",
        "",
        i18n.t(
          "biotrack_traceability.syncing_inventory_rooms_with_biotrack_traceability_failed"
        ) +
          `${
            syncingStatus.data
              ? syncingStatus.data
              : syncingStatus.error.message
          }`,
        "biotrack_traceability.syncing_failed",
        "",
        true,
        false,
        "ok"
      );
    }

    if (
      this.bioTrackTraceEnabled &&
      syncingStatus.data.includes("you can't add another room")
    ) {
      this.renderTraceabilityPopupModal(
        "biotrack_traceability.inventory_rooms_title",
        "",
        "biotrack_traceability.syncing_duplicate_room_description",
        "biotrack_traceability.syncing_duplicate_room",
        "",
        true,
        false,
        "ok"
      );
    }
  }

  public async disableModel(error: string) {
    if (!this.bioTrackTraceEnabled) {
      await this.renderTraceabilityPopupModal(
        "biotrack_traceability.inventory_rooms_title",
        "biotrack_traceability.delete_deactivate_status",
        error,
        "",
        "",
        true,
        false,
        "OK"
      );
    } else {
      await this.renderTraceabilityPopupModal(
        "biotrack_traceability.inventory_rooms_title",
        "biotrack_traceability.delete_deactivate_status",
        error,
        "",
        "",
        true,
        false,
        "OK"
      );
    }
  }

  public filter(header: TableHeader) {
    roomService.sortQuery(header);
  }

  public async triggerSearch(query?: HttpQuery) {
    roomService.setQuery(query);
    this.getRoomsAction();
  }

  protected async mounted() {
    if (this.bioTrackTraceEnabled) {
      RoomListComponent.currentBiotrackStatus = true;
    }

    this.setPageNav({
      title: "rooms.view_title",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.triggerSearch({ ...this.query, "q[is_active]": 1 });
    this.$changes.watch(pusherEvents.roomTouched, this.getRoomsAction);
  }

  protected beforeDestroy() {
    roomService.resetQuery();
  }
}
